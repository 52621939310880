import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./css/FeaturedTrips.css";

const trips = [
  {
    id: 1,
    destination: "Bali",
    image: "https://images.pexels.com/photos/21717843/pexels-photo-21717843/free-photo-of-rock-formation-at-kelingking-beach-in-indonesia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: 2,
    destination: "Iceland",
    image: "https://images.pexels.com/photos/5337743/pexels-photo-5337743.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    id: 3,
    destination: "Dubai",
    image: "https://images.pexels.com/photos/2044434/pexels-photo-2044434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: 4,
    destination: "Singapore",
    image: "https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

const FeaturedTrips = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleDestinationClick = (destination) => {
    navigate(`/packages#${destination.toLowerCase()}`); // Navigate to the destination's section
  };

  return (
    <section className="featured-trips">
      <h2>Featured Destinations</h2>
      <div className="trip-cards">
        {trips.map((trip) => (
          <div
            key={trip.id}
            className="trip-card"
            onClick={() => handleDestinationClick(trip.destination)} // Handle click
          >
            <img src={trip.image} alt={trip.destination} />
            <h3>{trip.destination}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturedTrips;
