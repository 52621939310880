import React, { useState, useRef, useEffect } from "react";
import "./css/Testimonials.css";
import Ananya from "./assets/ananya.jpeg"; 
import LeelaDevi from "./assets/leeladevi.jpeg"; 

const testimonials = [
  {
    name: "Ananya",
    review:
      "TripMyWay made my travel planning so easy! The destinations were amazing, and the customer service was top-notch.",
    image: Ananya,
    color: "#9d789b",
  },
  {
    name: "Leela Devi",
    review:
      "I had the best vacation ever thanks to TripMyWay. Everything was perfectly organized and hassle-free.",
    image: LeelaDevi,
    color: "#7a81a8",
  },
  // {
  //   name: "Dipanjan Saha",
  //   review:
  //     "Absolutely loved the experience! The team went above and beyond to make sure my trip was memorable.",
  //   image: "https://via.placeholder.com/100",
  //   color: "#6d5b98",
  // },
  // {
  //   name: "Indrani Datta",
  //   review:
  //     "From start to finish, my journey was incredible. TripMyWay is the best travel platform I’ve used!",
  //   image: "https://via.placeholder.com/100",
  //   color: "#5a5baf",
  // },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(1); // Default to mobile (1 slide at a time)
  const sliderRef = useRef(null);

  // Determine number of visible slides based on screen size
  useEffect(() => {
    const updateVisibleSlides = () => {
      if (window.innerWidth >= 1024) {
        setVisibleSlides(3); // Show 3 slides on desktop
      } else {
        setVisibleSlides(1); // Show 1 slide on smaller screens
      }
    };

    updateVisibleSlides();
    window.addEventListener("resize", updateVisibleSlides);
    return () => window.removeEventListener("resize", updateVisibleSlides);
  }, []);

  // Autoplay the testimonials
  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + visibleSlides) % testimonials.length
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoplayInterval); // Cleanup interval on component unmount
  }, [visibleSlides]);

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + visibleSlides) % testimonials.length
    );
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - visibleSlides + testimonials.length) % testimonials.length
    );
  };

  return (
    <section className="testimonials">
      <h2 className="testimonials-title">What Our Clients Say</h2>
      <div className="slider-container">
        <button className="prev-btn" onClick={handlePrevSlide}>
          &#10094;
        </button>
        <div
          className="slides"
          ref={sliderRef}
          style={{
            transform: `translateX(-${(currentIndex / visibleSlides) * 100}%)`,
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              className="slide"
              key={index}
              style={{ "--card-border-color": testimonial.color }}
            >
              <div className="testimonial-card">
                <img src={testimonial.image} alt={testimonial.name} />
                <h4>{testimonial.name}</h4>
                <p>{testimonial.review}</p>
              </div>
            </div>
          ))}
        </div>
        <button className="next-btn" onClick={handleNextSlide}>
          &#10095;
        </button>
      </div>
    </section>
  );
};

export default Testimonials;
