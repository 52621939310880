import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import "./css/PackageDetails.css";
import Navbar2 from "./Navbar2";

const PackageDetails = ({ packages }) => {
  const { id } = useParams(); // Get the package ID from the URL
  const packageDetails = packages.find((pkg) => pkg.id === parseInt(id));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(""); // "success", "error", or ""
  const formRef = useRef(); // Use a ref for the form

  if (!packageDetails) {
    return <p>Package not found</p>;
  }

  const openModal = () => {
    setIsModalOpen(true);
    setStatus(""); // Reset status on opening modal
  };

  const closeModal = (e) => {
    if (
      e.target.className === "modal-overlay" ||
      e.target.className === "close-modal-btn"
    ) {
      setIsModalOpen(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9z2t73l", // Service ID
        "template_po56t3o", // Template ID
        formRef.current,
        "2D868YPv6vcVWr4DB" // Public Key
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setStatus("success");
          e.target.reset(); // Reset form fields
        },
        (error) => {
          console.error("FAILED...", error.text);
          setStatus("error");
        }
      );
  };

  return (
    <div className="package-details">
      <Navbar2 />
      <img
        src={packageDetails.image}
        alt={packageDetails.title}
        className="details-image"
      />
      <h1>{packageDetails.title}</h1>
      <p>{packageDetails.description}</p>
      <p className="details-price">Price: {packageDetails.price}</p>
      <button className="enquire-now-btn" onClick={openModal}>
        Enquire Now
      </button>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            <button className="close-modal-btn" onClick={closeModal}>
              ✖
            </button>
            <h3>Enquire About {packageDetails.title}</h3>
            {status === "success" && (
              <div className="success-message">
                Your enquiry has been sent successfully!
              </div>
            )}
            {status === "error" && (
              <div className="error-message">
                Something went wrong. Please try again.
              </div>
            )}
            <form ref={formRef} onSubmit={handleSubmit} className="modal-form">
              {/* Hidden field to send the package title */}
              <input
                type="hidden"
                name="package_name"
                value={packageDetails.title}
              />
              <div className="form-group">
                <input
                  type="text"
                  name="user_name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="user_email"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="origin"
                  placeholder="Your Origin"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="user_message"
                  placeholder="Your Message"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageDetails;
