import React from "react";
import "./css/AboutUs.css";

const AboutUs = () => {
  return (
    <section className="about-us">
      <h2>About Us</h2>
      <p>
        TripMyWay is dedicated to making your travel experience unforgettable.
        From personalized itineraries to world-class customer service, we ensure
        your journey is seamless and enriching.
      </p>
    </section>
  );
};

export default AboutUs;
