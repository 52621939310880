import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser"; // Import Email.js
import "./css/EnquiryForm.css";

const EnquiryForm = () => {
  const formRef = useRef(); // Use a ref for the form
  const [status, setStatus] = useState(""); // "success", "error", or ""

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Use Email.js to send the form data
    emailjs
      .sendForm(
        "service_9z2t73l", // Service ID
        "template_po56t3o", // Template ID
        formRef.current,
        "2D868YPv6vcVWr4DB" // Public Key
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setStatus("success");
          // Reset form fields
          e.target.reset();
        },
        (error) => {
          console.error("FAILED...", error.text);
          setStatus("error");
        }
      );
  };

  return (
    <section id="enquiry-form" className="enquiry-form">
      <div className="form-container">
        <h2>Plan Your Perfect Journey</h2>
        <p className="form-description">
          Fill out the form below, and our travel experts will get in touch with you shortly.
        </p>
        {status === "success" && (
          <div className="success-message">
            Thank you! Your enquiry has been submitted.
          </div>
        )}
        {status === "error" && (
          <div className="error-message">
            Oops! Something went wrong. Please try again.
          </div>
        )}
      <form ref={formRef} onSubmit={handleSubmit}>
  <div className="form-group">
    <input
      type="text"
      name="user_name" // Match the field name with your Email.js template
      placeholder="Name"
      required
    />
  </div>
  <div className="form-group">
    <input
      type="email"
      name="user_email" // Match the field name with your Email.js template
      placeholder="Email"
      required
    />
  </div>
  <div className="form-group">
    <input
      type="text"
      name="origin" // Match the field name with your Email.js template
      placeholder="Origin"
      required
    />
  </div>
  <div className="form-group">
    <input
      type="text"
      name="destination" // Match the field name with your Email.js template
      placeholder="Destination"
      required
    />
  </div>
  <div className="form-group">
    <textarea
      name="message" // Match the field name with your Email.js template
      rows="4"
      placeholder="Share more details"
    ></textarea>
  </div>
  <button type="submit" className="submit-btn">
    Submit Enquiry
  </button>
</form>

      </div>
    </section>
  );
};

export default EnquiryForm;
