import React from "react";
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon
import "./css/Footer.css";
import logo from "../logo.png"; // Import logo
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <Link to="/">
          <img src={logo} alt="TripMyWay Logo" />
        </Link>
      </div>
      <p>&copy; 2024 TripMyWay. All rights reserved.</p>
      <ul className="footer-links">
        <li>Privacy Policy</li>
        <li>Terms of Service</li>
        <li>Contact Us</li>
      </ul>
      <p className="footer-email">
        Email us at: <a href="mailto:letstripmyway@gmail.com">letstripmyway@gmail.com</a>
      </p>
      <p className="footer-phone">
        Call us at: <a href="tel:+918951652110">+91 8951652110</a>
      </p>
      <div className="social-media">
        <a
          href="https://www.instagram.com/letstripmyway"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
          className="instagram-link"
        >
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
