import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../logo.png"; // Update the path as needed
import "./css/Navbar2.css"; // Ensure you have this CSS file

const Navbar2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="navbar2" aria-label="Main Navigation">
      <Link to="/">
        <img src={Logo} alt="TripMyWay Logo" className="navbar2-logo" />
      </Link>
      <button
        className={`navbar2-hamburger ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
        aria-label="Toggle Navigation Menu"
      >
        {isMenuOpen ? "✖" : "☰"}
      </button>
      <ul className={`navbar2-links ${isMenuOpen ? "active" : ""}`}>
        <li>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/packages" onClick={() => setIsMenuOpen(false)}>
            Packages
          </Link>
        </li>
        <li>
          <a href="#enquiry-form" onClick={() => setIsMenuOpen(false)}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar2;
