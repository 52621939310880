import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeroSection from "./components/HeroSection";
import FeaturedTrips from "./components/FeaturedTrips";
import AboutUs from "./components/AboutUs";
import EnquiryForm from "./components/EnquiryForm";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import Packages from "./components/Packages"; // Import the Packages component
import WhatsAppFloater from "./components/WhatsAppFloater";
import PackageDetails from "./components/PackageDetails"; 


const packages = [
  {
      id: 1,
      place: "Bali",
      title: "Bali Beach Escape",
      description: "Relax on the sandy beaches of Bali with our 5-day luxury package.",
      price: "₹99,999",
      image: "https://images.pexels.com/photos/2474690/pexels-photo-2474690.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 2,
      place: "Bali",
      title: "Bali Adventure Trek",
      description: "Explore Bali's lush jungles and waterfalls with a 7-day adventure.",
      price: "₹88,999",
      image: "https://images.pexels.com/photos/3762284/pexels-photo-3762284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 3,
      place: "Bali",
      title: "Cultural Bali Tour",
      description: "Discover Bali's rich culture and heritage in a 6-day tour.",
      price: "₹92,999",
      image: "https://images.pexels.com/photos/2166559/pexels-photo-2166559.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 4,
      place: "Dubai",
      title: "Dubai City Tour",
      description: "Discover Dubai's skyline and cultural gems in a 4-day city tour.",
      price: "₹94,999",
      image: "https://images.pexels.com/photos/2115367/pexels-photo-2115367.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 5,
      place: "Dubai",
      title: "Dubai Desert Safari",
      description: "Experience the thrill of a 3-day desert safari adventure.",
      price: "₹99,999",
      image: "https://images.pexels.com/photos/936250/pexels-photo-936250.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 6,
      place: "Dubai",
      title: "Dubai Luxury Cruise",
      description: "Enjoy a luxurious 2-day cruise with stunning views of Dubai.",
      price: "₹95,999",
      image: "https://images.pexels.com/photos/5087055/pexels-photo-5087055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 7,
      place: "Singapore",
      title: "Singapore City Highlights",
      description: "Discover the best of Singapore with our 4-day city tour.",
      price: "₹95,999",
      image: "https://images.pexels.com/photos/326787/pexels-photo-326787.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 8,
      place: "Singapore",
      title: "Singapore Family Fun",
      description: "Enjoy family-friendly attractions in a 5-day Singapore vacation.",
      price: "₹95,999",
      image: "https://images.pexels.com/photos/262577/pexels-photo-262577.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 9,
      place: "Singapore",
      title: "Singapore Night Safari",
      description: "Experience Singapore's wildlife at night with a 2-day safari.",
      price: "₹84,999",
      image: "https://images.pexels.com/photos/290597/pexels-photo-290597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 10,
      place: "Iceland",
      title: "Iceland Northern Lights",
      description: "Witness the magical Northern Lights with our 5-day Iceland tour.",
      price: "₹199,999",
      image: "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 11,
      place: "Iceland",
      title: "Iceland Glacier Adventure",
      description: "Explore Iceland's glaciers and volcanoes in a thrilling 7-day package.",
      price: "₹159,999",
      image: "https://images.pexels.com/photos/27182326/pexels-photo-27182326/free-photo-of-icebergs-on-seashore-in-iceland.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      id: 12,
      place: "Iceland",
      title: "Iceland Scenic Drives",
      description: "Enjoy breathtaking scenic drives across Iceland in a 6-day tour.",
      price: "₹184,999",
      image: "https://images.pexels.com/photos/27244356/pexels-photo-27244356/free-photo-of-suv-driving-car-in-winter-landscape.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
];

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/enquiry" element={<EnquiryForm />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/packages" element={<Packages packages={packages} />} />
        <Route path="/packages/:id" element={<PackageDetails packages={packages} />} /> {/* Add this */}
      </Routes>
    
      <Footer />

      <WhatsAppFloater />
    </Router>
  );
};

export default App;
