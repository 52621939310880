import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./css/Navbar.css";
import heroLogo from "../logo.png";

const Navbar = () => {
  const [isHeroVisible, setIsHeroVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleContactClick = (e) => {
    if (location.pathname !== "/") {
      e.preventDefault();
      window.location.href = "/#enquiry-form";
    } else {
      e.preventDefault();
      const enquiryForm = document.getElementById("enquiry-form");
      if (enquiryForm) {
        enquiryForm.scrollIntoView({ behavior: "smooth" });
        setIsMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    const heroSection = document.querySelector(".hero-section");

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeroVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (heroSection) {
      observer.observe(heroSection);
    }

    return () => {
      if (heroSection) {
        observer.unobserve(heroSection);
      }
    };
  }, []);

  return (
    <nav
      className={`navbar ${!isHeroVisible ? "navbar-bg" : ""}`}
      aria-label="Main Navigation"
    >
      <Link to="/">
        <img src={heroLogo} alt="TripMyWay Logo" className="logo" />
      </Link>
      <button
        className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
        aria-label="Toggle Navigation Menu"
        style={{ color: isHeroVisible ? "white" : "#062239" }}
      >
        {isMenuOpen ? "✖" : "☰"}
      </button>
      <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/packages">
          <li>Packages</li>
        </Link>
        <a href="#enquiry-form" onClick={handleContactClick}>
          <li>Contact</li>
        </a>
      </ul>
    </nav>
  );
};

export default Navbar;
