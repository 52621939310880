import React from "react";
import "./css/WhatsAppFloater.css"; // Create a separate CSS file for styling
import { FaWhatsapp } from "react-icons/fa"; // FontAwesome icon for WhatsApp

const WhatsAppFloater = () => {
  const openWhatsApp = () => {
    const whatsappURL = "https://wa.me/918951652110?text=Hi%2C%20I%27m%20interested%20in%20booking%20a%20trip%20with%20TripMyWay."; 
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="whatsapp-floater" onClick={openWhatsApp}>
      <FaWhatsapp className="whatsapp-icon" />
    </div>
  );
};

export default WhatsAppFloater;
