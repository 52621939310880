import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logo.png";


const PackageCard = ({ id, title, description, price, image }) => {
  const handleEnquireNow = () => {
    const phoneNumber = "+918951652110"; // Replace with your WhatsApp number
    const message = encodeURIComponent(
      `Hello! I am interested in the "${title}" package. Could you please provide more details?`
    );
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank"); // Open WhatsApp URL in a new tab
  };

  return (
    <div className="package-card">
  
      
        <img src={image} alt={title} className="package-image" />
        <h4 className="package-title">{title}</h4>
      
      <div className="package-content">
        <p className="package-description">{description}</p>
        <p className="package-price"> {price} onwards</p>
        <Link to={`/packages/${id}`} className="details-link">
        <button className="book-now-btn">
          Details
        </button>
        </Link>
      </div>
    </div>
  );
};

export default PackageCard;
