import React, { useState, useEffect } from "react";
import "./css/HeroSection.css";
import logo from "../logo.png";
import FeaturedTrips from "./FeaturedTrips";
import AboutUs from "./AboutUs";
import Navbar from "./NavBar";
import { Link } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import Testimonials from "./Testimonials";

const slides = [
  "https://images.pexels.com/photos/1802183/pexels-photo-1802183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/1009136/pexels-photo-1009136.jpeg?auto=compress&cs=tinysrgb&w=800",
  "https://images.pexels.com/photos/1029188/pexels-photo-1029188.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
];

const videoSrc =
  "https://videos.pexels.com/video-files/2169879/2169879-uhd_2560_1440_30fps.mp4";

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mobile slideshow
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 3000); // Change every 3 seconds
      return () => clearInterval(interval);
    }
  }, [isMobile]);

  return (
    <div>
      <Navbar />
      <header className="hero-section">
        <div className="media-container">
          {isMobile ? (
            <img
              src={slides[currentSlide]}
              alt={`Slide ${currentSlide}`}
              className="hero-image"
            />
          ) : (
            <video className="hero-video" autoPlay loop muted>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <Link to="/">
        <img src={logo} alt="TripMyWay Logo" className="mobile-logo" />
        </Link>
        <div className="hero-content">
          <h1>Your Budget, Our Planning</h1>
          <p>Plan your trip with the best destinations and experiences</p>
          <button
            className="enquire-now-btn"
            onClick={() =>
              document.getElementById("enquiry-form").scrollIntoView({ behavior: "smooth" })
            }
          >
            Enquire Now
          </button>
        </div>
      </header>
      <FeaturedTrips />
      <AboutUs />
      <EnquiryForm />
      <Testimonials/>
    </div>
  );
};

export default HeroSection;
